<script setup>
import { onMounted, ref } from 'vue';

defineProps({
  modelValue: String | Number,
  label: {
    type: String,
    default: '',
  },
});

defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
  if (input.value.hasAttribute('autofocus')) {
    input.value.focus();
  }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
  <input
    ref="input"
    :placeholder="label"
    :value="modelValue"
    class="rounded-md border py-1.5 text-gray-900 border-gray-200 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-sm sm:leading-6"
    type="text"
    @input="$emit('update:modelValue', $event.target.value)"
  >
</template>
